<template>
  <div class="copyright">
    <p>شيپاگەر ساباقحاناسى</p>
    <p class="menus">
      <a href="javascript:;" @click="showPopup">سيستيمانى ۇعىسۋ</a>
      <!-- <span class="split_line"></span>
      <a href="javascript:;" @click="showPopup">حابارلاسۋ</a> -->
    </p>
    <!-- <van-popup v-model="show">
      <div class="qrCode">
        <img src="https://mmbiz.qpic.cn/mmbiz_png/ibjLicHT38d12CrL7gCDbA2Ag6oDWRBYhu296NQCevLtwSIiafX16J3nXFHR2VIFpVrWARd26YY7TpicCOIPsRBt2g/640?wx_fmt=png&amp;from=appmsg" />
      </div>
    </van-popup> -->
  </div>
</template>

<script>
export default {
  name: 'Copyright',
  data () {
    return {
      show: false
    }
  },
  methods: {
    showPopup () {
      this.show = true
    }
  }
}
</script>

<style lang="less" scoped>
img {
  pointer-events: auto; /* 禁止长按图片保存 */
}
.copyright {
  text-align: center;
  font-size: 26px;
  color: #999;
  margin-top: 50px;
  p {
    &:nth-child(2) {
      margin: 10px 0;
    }
  }
  .menus {
    display: flex;
    align-items: center;
    justify-content: center;
    a {
      color: #1989fa;
    }
    .split_line {
      display: inline-block;
      width: 2px;
      height: 20px;
      background-color: #999;
      margin: -10px 10px 0;
    }
  }

  .qrCode {
    text-align: center;
    padding-bottom: 20px;
    overflow: hidden;
    img {
      width: 500px;
    }
  }
}

/deep/.van-popup {
  border-radius: 10px;
}
</style>
